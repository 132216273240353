.app-menu > div {
    position: relative;
    width: 15rem;
  }
  
  .app-menu .app-menu__menu-item {
    padding: 0;
  }
  
  .app-menu .app-menu__menu-item.is--padded {
    padding-left: 1.5rem;
  }
  
  .app-menu .app-menu__menu-item a {
    width: 100%;
    height: 100%;
    padding: 12px 22px;
    color: #000;
  }
  .app-menu .app-menu__menu-item:hover a {
    color: #fff;
  }
  
  .app-menu .app-menu__menu-item.Mui-selected a {
    color: #fff;
  }
  
  .app-menu .app-menu__menu-item:hover {
    background-color: #5c6bc0;
  }
  
  .app-menu .app-menu__menu-item.Mui-selected,
  .app-menu .app-menu__menu-item.Mui-selected:hover {
    background: #3f51b5;
  }
  
  .app-menu .menu-item__text {
    padding: 0;
  }
  